import Vue from 'vue'
import VueRouter from 'vue-router'


import Survey from '../views/Survey.vue';
import SurveySpa from '../views/Survey-Spa.vue';


Vue.use(VueRouter)

const routes = [{
    path: '/English',
    name: 'English',
    component: Survey,
  },
  {
    path: '/Spanish',
    name: 'Spanish',
    component: SurveySpa,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router